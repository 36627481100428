//
// Main init file of global bootstrap and theme functions, mixins, variables and config
//

// Custom functions & mixins
@import '../../../../shared/metronic/assets/sass/core/base/functions';
@import '../../../../shared/metronic/assets/sass/core/base/mixins';
@import '../../../../shared/metronic/assets/sass/core/components/mixins';
@import '../../../../shared/metronic/assets/sass/core/vendors/plugins/mixins';

// Custom variables
@import '../../../../shared/metronic/assets/sass/custom/variables';
@import '../../../../shared/metronic/assets/sass/core/components/variables';

// Bootstrap initializaton
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/utilities';

// 3rd-Party plugins variables
@import '../../../../shared/metronic/assets/sass/core/vendors/plugins/variables';

// Custom layout variables
@import '../../../../shared/metronic/assets/sass/custom/variables.layout';
@import '../../../../shared/metronic/assets/sass/layout/variables';

@import '../../../../shared/metronic/assets/sass/custom/utilities';

// Uppy initialization
@import '~@uppy/core/dist/style.css';
@import '~@uppy/drag-drop/dist/style.css';

.root {
  display: flex;
  flex-direction: column;
  padding: 40px;

  .title {
    font-size: 18px;
  }

  .product {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 0;

    img {
      width: 60px;
      height: 60px;
    }

    .price {
      font-weight: 700;
      font-size: 16px;
    }
  }

  .total {
    display: flex;
    justify-content: flex-end;
    padding: 20px 0;
    border-top: 1px solid rgba($color: $primary, $alpha: 0.15);

    b {
      font-weight: 700;
    }
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
