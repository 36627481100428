//
// Main init file of global bootstrap and theme functions, mixins, variables and config
//

// Custom functions & mixins
@import '../../../../shared/metronic/assets/sass/core/base/functions';
@import '../../../../shared/metronic/assets/sass/core/base/mixins';
@import '../../../../shared/metronic/assets/sass/core/components/mixins';
@import '../../../../shared/metronic/assets/sass/core/vendors/plugins/mixins';

// Custom variables
@import '../../../../shared/metronic/assets/sass/custom/variables';
@import '../../../../shared/metronic/assets/sass/core/components/variables';

// Bootstrap initializaton
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/utilities';

// 3rd-Party plugins variables
@import '../../../../shared/metronic/assets/sass/core/vendors/plugins/variables';

// Custom layout variables
@import '../../../../shared/metronic/assets/sass/custom/variables.layout';
@import '../../../../shared/metronic/assets/sass/layout/variables';

@import '../../../../shared/metronic/assets/sass/custom/utilities';

// Uppy initialization
@import '~@uppy/core/dist/style.css';
@import '~@uppy/drag-drop/dist/style.css';

$product-border-radius: 10px;

.root {
  display: flex;
  flex: 1 0 21%;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 10px;
  padding: 20px;
  background-color: $white;
  border-radius: $product-border-radius;
  text-transform: none;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 6px 50px 5px rgba(15, 15, 33, 0.1);
  }

  .imageWrapper {
    .image {
      border-radius: 10px;
      min-width: 80px;
      height: 80px;
    }
  }

  .contentWrapper {
    display: flex;
    flex-direction: column;
    flex: 1;

    .headerWrapper {
      display: flex;
      flex: 1;
      flex-direction: column;
      padding: 20px 0;

      .subjectWrapper {
        .name {
          font-size: 18px;
        }

        .category {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          color: rgba($color: $black, $alpha: 0.7);
          font-size: 12px;
          margin-top: 10px;

          > span {
            font-weight: 700;
          }
        }

        .stage {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          margin-top: 10px;
          color: rgba($color: $black, $alpha: 0.7);
          font-size: 12px;

          > span {
            font-weight: 700;
          }
        }
      }
    }

    .descriptionWrapper {
      display: flex;
      .description {
        margin-bottom: 40px;
        font-size: 14px;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
        white-space: pre-wrap;
        * {
          display: inline;
        }
      }
    }

    .priceWrapper {
      display: flex;
      flex: 3;
      justify-content: flex-end;
      align-items: flex-end;
      text-align: right;
      .price {
        font-size: 16px;
        font-weight: 700;
        color: $text-primary;
      }
      .iconCart {
        margin-left: 20px;
        margin-bottom: 2px;
        z-index: 99;
      }
    }
  }
}

.rootPromoted {
  flex-basis: 42%;
  flex-direction: row;
  background-color: #e1fdff;
  padding: 0px;
  .imageWrapperPromoted {
    display: flex;
    flex: 2;
    border-top-left-radius: $product-border-radius;
    border-bottom-left-radius: $product-border-radius;
    .imagePromoted {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      min-height: 100%;
      min-width: 100%;
      object-fit: cover;
    }
  }

  .subjectWrapperPromoted {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }

  .contentWrapper {
    display: flex;
    flex: 5;
    flex-direction: column;
    border-top-right-radius: $product-border-radius;
    border-bottom-right-radius: $product-border-radius;
    padding: 20px;
  }
}
