//
// Main init file of global bootstrap and theme functions, mixins, variables and config
//

// Custom functions & mixins
@import '../../metronic/assets/sass/core/base/functions';
@import '../../metronic/assets/sass/core/base/mixins';
@import '../../metronic/assets/sass/core/components/mixins';
@import '../../metronic/assets/sass/core/vendors/plugins/mixins';

// Custom variables
@import '../../metronic/assets/sass/custom/variables';
@import '../../metronic/assets/sass/core/components/variables';

// Bootstrap initializaton
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/utilities';

// 3rd-Party plugins variables
@import '../../metronic/assets/sass/core/vendors/plugins/variables';

// Custom layout variables
@import '../../metronic/assets/sass/custom/variables.layout';
@import '../../metronic/assets/sass/layout/variables';

@import '../../metronic/assets/sass/custom/utilities';

// Uppy initialization
@import '~@uppy/core/dist/style.css';
@import '~@uppy/drag-drop/dist/style.css';

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  border-color: $primary;
  background-color: $white;
  padding: 20px;

  .headerContainer {
    display: flex;
    flex: 0 1;
    justify-content: flex-end;
    align-items: flex-start;
    .typeTacticWrapper {
      padding: 10px 5px;
      border-radius: 10px;
      text-align: center;
      margin-right: 10px;
    }
    .typeTacticLiveOps {
      background-color: $azure;
    }
    .typeTacticMarketing {
      background-color: $ghostWhite;
    }
    .likeIcon {
      width: 20px;
      height: 20px;
    }
  }
  .descriptionContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding-bottom: 80px;
    .description {
      display: flex;
      padding-top: 10px;
      font-size: 18px;
      font-weight: bold;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      * {
        display: inline;
      }
    }
    .stage {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-top: 10px;
      color: rgba($color: $black, $alpha: 0.7);
      font-size: 12px;

      > span {
        font-weight: 700;
      }
    }

    .segmentListContainer {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      padding-top: 10px;
      gap: 10px;
      .segmentWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
      }
      .segmentImage {
        width: 20px;
        height: 20px;
      }

      .segmentName {
        display: none;
      }
    }
  }

  .authorContainer {
    display: flex;
    flex: 1;
    align-items: flex-end;
    justify-content: flex-start;

    .icon {
      width: 22px;
      height: 22px;
      min-width: 22px;
      min-height: 22px;
    }

    .signContainer {
      display: flex;
      flex-direction: column;
      padding-left: 11px;
      max-width: 190px;

      .authorName {
        display: flex;
        font-size: 11.571px;
        font-weight: 400;
        line-height: 16.393px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        * {
          display: inline;
        }
      }

      .created {
        display: flex;
        font-size: 8.679px;
        font-weight: 400;
        line-height: 13.5px;
      }
    }
  }
}

.containerHovered {
  &:hover {
    -webkit-box-shadow: 8px -5px 26px -20px rgba(66, 68, 90, 1);
    -moz-box-shadow: 8px -5px 26px -20px rgba(66, 68, 90, 1);
    box-shadow: 8px -5px 26px -20px rgba(66, 68, 90, 1);
    .segmentListContainer {
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 10px 0px;
      .segmentWrapper {
        display: flex;
        width: 50%;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
      }
      .segmentName {
        font-size: 9px;
        font-style: normal;
        color: $gray-800;
        font-weight: 700;
        line-height: 14px;
        padding-left: 5px;
        display: block;
      }
    }
  }
}

.borderTacticLiveOps {
  border: 10px;
  border-radius: 20px;
  border: 1px solid $aero;
}

.borderTacticMarketing {
  border: 10px;
  border-radius: 20px;
  border: 1px solid $primary;
}
