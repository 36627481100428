//
// Main init file of global bootstrap and theme functions, mixins, variables and config
//

// Custom functions & mixins
@import '../../../metronic/assets/sass/core/base/functions';
@import '../../../metronic/assets/sass/core/base/mixins';
@import '../../../metronic/assets/sass/core/components/mixins';
@import '../../../metronic/assets/sass/core/vendors/plugins/mixins';

// Custom variables
@import '../../../metronic/assets/sass/custom/variables';
@import '../../../metronic/assets/sass/core/components/variables';

// Bootstrap initializaton
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/utilities';

// 3rd-Party plugins variables
@import '../../../metronic/assets/sass/core/vendors/plugins/variables';

// Custom layout variables
@import '../../../metronic/assets/sass/custom/variables.layout';
@import '../../../metronic/assets/sass/layout/variables';

@import '../../../metronic/assets/sass/custom/utilities';

// Uppy initialization
@import '~@uppy/core/dist/style.css';
@import '~@uppy/drag-drop/dist/style.css';

.arrowButton {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  right: 1%;
  opacity: 1;
  user-select: none;
  font-size: 48px;
  background-color: rgba(255, 255, 255, 0.7);
  border: 1px solid lightgrey;
  align-self: center;
  width: 35px;
  height: 107px;

  &:disabled {
    opacity: 0;
    cursor: default;
  }
}

.leftBorder {
  border-radius: 0px 10px 10px 0px;
}

.rightBorder {
  border-radius: 10px 0px 0px 10px;
}

.arrowRight {
  transform: scaleX(-1);
}
