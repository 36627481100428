//
// Main init file of global bootstrap and theme functions, mixins, variables and config
//

// Custom functions & mixins
@import '../../metronic/assets/sass/core/base/functions';
@import '../../metronic/assets/sass/core/base/mixins';
@import '../../metronic/assets/sass/core/components/mixins';
@import '../../metronic/assets/sass/core/vendors/plugins/mixins';

// Custom variables
@import '../../metronic/assets/sass/custom/variables';
@import '../../metronic/assets/sass/core/components/variables';

// Bootstrap initializaton
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/utilities';

// 3rd-Party plugins variables
@import '../../metronic/assets/sass/core/vendors/plugins/variables';

// Custom layout variables
@import '../../metronic/assets/sass/custom/variables.layout';
@import '../../metronic/assets/sass/layout/variables';

@import '../../metronic/assets/sass/custom/utilities';

// Uppy initialization
@import '~@uppy/core/dist/style.css';
@import '~@uppy/drag-drop/dist/style.css';

.root {
  position: absolute;
  display: flex;
  top: 15px;
  right: 55px;
  font-size: 20px;
  cursor: pointer;

  &.noItems {
    cursor: initial !important;
  }

  .cart {
    display: inline-block;
    position: relative;

    .quantity {
      position: absolute;
      top: 15%;
      left: 58%;
      transform: translate(-50%, -50%);
      color: $primary;
      font-weight: 700;
    }
  }
}

.price {
  margin-left: 6px;
  font-size: 18px;
  font-weight: 700;
  color: $text-primary;
}

.popover {
  padding: 20px !important;
  cursor: initial;

  .header {
    font-size: 18px;
    font-weight: 700;
    color: $text-primary;
  }

  .loader {
    padding: 20px;
    margin: 0 auto;
  }

  .products {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid rgba($color: $text-primary, $alpha: 0.2);
  }

  .summary {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    row-gap: 10px;
    font-size: 18px;
    margin-top: 15px;
  }
}

.item {
  display: flex;
  align-items: center;
  padding: 20px 0;

  .name {
    min-width: 200px;
    margin-right: 20px;
  }

  .cartImg {
    width: 60px;
    height: 60px;
    margin-right: 20px;
    border-radius: 10px;
  }

  .closeButton {
    @include svg-bg-icon(close, #3A4472);
    width: 9px;
    height: 9px;
    margin: 0 20px;
    cursor: pointer;
  }

  .counter {
    margin-right: auto;
  }
}