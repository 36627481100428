.aside {
  .aside-footer {
    padding-left: 24px;
    padding-right: 24px;
    margin-bottom: 16px;
  }

  .aside-plan {
    width: fit-content;
    min-height: 38px;
    margin-left: get($aside-config, padding-x);
    margin-right: get($aside-config, padding-x);
    padding: 6px 12px;
    background-color: rgba(255, 255, 255, 0.7);
    gap: 8px;
    cursor: pointer;
    transition: .3s;

    &--expired {
      background-color: #ff5a55;
      color: $white;
    }

    &-title {
      color: #3E51E5;
      transition: .3s ease;
    }

    &-expired {
      transition: .3s ease;
    }
  }

  .plan-popover {
    display: flex;
    flex-direction: column;
    padding: 20px;
    cursor: initial;

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-bottom: 10px;

      >h3 {
        font-size: 1.2rem;
        text-transform: uppercase;
      }

      >div {
        @include svg-bg-icon(close, #3A4472);
        width: 9px;
        height: 9px;
        cursor: pointer;
      }
    }

    &-body {
      display: flex;
      flex-direction: column;

      &-info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;

        .plan-name {
          color: rgba(23, 32, 71, 0.7);
          margin-left: 10px;
          font-size: 12px;
          font-weight: 700;

          > span {
            text-transform: capitalize;
          }
        }

        &-time {
          display: flex;
          align-items: center;

          &--isExpiring {
            color: #FD506F;
            font-weight: 700;
          }

          >span {
            font-size: 12px;
            margin-left: 10px;
            line-height: 10px;
          }
        }
      }

      &-upgrade {
        display: flex;
        background-color: rgba(62, 81, 229, 0.1);
        padding: 15px;
        border-radius: 10px;
        width: 100%;
        height: 140px;

        .upgrade-info {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          flex: 1 0 auto;

          &--bestPlan {
            justify-content: center;
          }

          .upgrade-text {
            color: $black;
            font-weight: 700;
            width: 150px;
          }

          button {
            width: fit-content;
          }
        }

        .upgrade-icon {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
        }
      }
    }
  }

  .popover-arrow {
    display: none;
  }
}

@include media-breakpoint-up(lg) {
  .aside {
    @include border-radius(get($aside-config, border-radius));

    .aside-logo,
    .aside-footer {
      padding-left: get($aside-config, padding-x);
      padding-right: get($aside-config, padding-x);
    }

    .aside-footer {
      position: absolute;
      bottom: 16px;
      left: 0;
      margin-bottom: 0;
    }

    .aside-fixed & {
      position: fixed;
      top: get($content-spacing, desktop);
      bottom: get($content-spacing, desktop);
      left: get($content-spacing, desktop);
      z-index: get($aside-config, z-index);
      overflow: visible;
    }

    &.aside-dark {
      background-color: get($aside-config, bg-color);
      background-repeat: no-repeat;
      background-size: cover;
    }

    .aside-toggle {
      position: absolute;
      right: -8px;
      bottom: 24px;
      width: 16px;
      height: 16px;
      background-color: #fff;
    }

    .logo-minimize {
      display: none;
    }

    .aside-footer {
      .user-name {
        opacity: 1;
        transition: opacity get($aside-config, transition-speed) ease;
        transition-delay: 0.1s;
      }
    }

    .aside-menu {

      .menu-content,
      .menu-title {
        transition: opacity get($aside-config, transition-speed) ease 0.2s;
      }
    }

    [data-kt-aside-minimize='on'] & {

      // Not hovered mode
      &:not(.aside-hoverable),
      &:not(:hover) {
        .aside-footer {
          padding-left: 16px;
          padding-right: 16px;

          .user-name {
            opacity: 0;
            transition: none;
            transition-delay: 0s;
          }
        }
      }

      .aside-menu {
        .menu-link {
          &:hover {
            .menu-icon {
              svg {
                path {
                  fill: #fff !important;
                  fill-opacity: 1 !important;
                }
              }
            }
          }
        }
      }
    }
  }
}