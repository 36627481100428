//
// Main init file of global bootstrap and theme functions, mixins, variables and config
//

// Custom functions & mixins
@import '../../../metronic/assets/sass/core/base/functions';
@import '../../../metronic/assets/sass/core/base/mixins';
@import '../../../metronic/assets/sass/core/components/mixins';
@import '../../../metronic/assets/sass/core/vendors/plugins/mixins';

// Custom variables
@import '../../../metronic/assets/sass/custom/variables';
@import '../../../metronic/assets/sass/core/components/variables';

// Bootstrap initializaton
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/utilities';

// 3rd-Party plugins variables
@import '../../../metronic/assets/sass/core/vendors/plugins/variables';

// Custom layout variables
@import '../../../metronic/assets/sass/custom/variables.layout';
@import '../../../metronic/assets/sass/layout/variables';

@import '../../../metronic/assets/sass/custom/utilities';

// Uppy initialization
@import '~@uppy/core/dist/style.css';
@import '~@uppy/drag-drop/dist/style.css';

.item {
  border-bottom: 1px solid rgba(62, 81, 229, 0.1);
}

.title {
  span {
    color: $primary;
  }
}

.icon {
  width: 6px;
  height: 10px;
}

.description {
  color: #172047b3;
}
