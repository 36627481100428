//
// Main init file of global bootstrap and theme functions, mixins, variables and config
//

// Custom functions & mixins
@import '../../metronic/assets/sass/core/base/functions';
@import '../../metronic/assets/sass/core/base/mixins';
@import '../../metronic/assets/sass/core/components/mixins';
@import '../../metronic/assets/sass/core/vendors/plugins/mixins';

// Custom variables
@import '../../metronic/assets/sass/custom/variables';
@import '../../metronic/assets/sass/core/components/variables';

// Bootstrap initializaton
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/utilities';

// 3rd-Party plugins variables
@import '../../metronic/assets/sass/core/vendors/plugins/variables';

// Custom layout variables
@import '../../metronic/assets/sass/custom/variables.layout';
@import '../../metronic/assets/sass/layout/variables';

@import '../../metronic/assets/sass/custom/utilities';

// Uppy initialization
@import '~@uppy/core/dist/style.css';
@import '~@uppy/drag-drop/dist/style.css';

.quickHelp {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  box-shadow: 0 6px 50px 5px rgba(79, 79, 176, 0.05);
  overflow: hidden;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(62, 81, 229, 0.1);
  padding: 20px 16px 16px 16px;

  @include media-breakpoint-up(lg) {
    padding: 20px 40px 16px 40px;
  }
}

.body {
  overflow-y: auto;
}

.footer {
  position: relative;
  padding: 36px 16px 20px 16px;
  margin-top: auto;

  @include media-breakpoint-up(lg) {
    padding: 56px 40px 40px 40px;
  }
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 16px;
  left: calc(50% - 24px);

  @include media-breakpoint-up(lg) {
    top: 26px;
  }

  span {
    padding: 0;
    width: 6px;
    height: 6px;
    background: rgba(62, 81, 229, 0.15);
    border-radius: 50%;
    margin: 0 5px;
  }

  .active {
    background-color: $primary;
  }
}
