/* i had to add !important rule because mixins added on top where overriding all scrollbar styles */
/* also css file, dont see color variables so I had to type them as hex */

.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
  height: 48px !important;
  background: #ecedfb !important;
  background-clip: content-box !important;
  border: 20px solid transparent !important;
  border-radius: 100px !important;
}

.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar-thumb {
  background: #c2c3d6 !important;
  background-clip: content-box !important;
  border: 20px solid transparent !important;
  border-radius: 50px !important;
  background-color: black;
}

.react-horizontal-scrolling-menu--scroll-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: auto;
  display: flex;
  gap: 10px;
  padding: 0 20px;
}

.react-horizontal-scrolling-menu--item {
  display: flex;
}

@-moz-document url-prefix() {
    .react-horizontal-scrolling-menu--item {
        padding-bottom: 16px;
    }
}

.react-horizontal-scrolling-menu--arrow-left {
  align-self: center;
  left: 40px;
  position: absolute;
  z-index: 30;
}

.react-horizontal-scrolling-menu--arrow-right {
  align-self: center;
  width: 40px;
  height: 107px;
  position: absolute;
  z-index: 30;
  right: 35px;
}
