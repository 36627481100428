//
// Main init file of global bootstrap and theme functions, mixins, variables and config
//

// Custom functions & mixins
@import '../../../metronic/assets/sass/core/base/functions';
@import '../../../metronic/assets/sass/core/base/mixins';
@import '../../../metronic/assets/sass/core/components/mixins';
@import '../../../metronic/assets/sass/core/vendors/plugins/mixins';

// Custom variables
@import '../../../metronic/assets/sass/custom/variables';
@import '../../../metronic/assets/sass/core/components/variables';

// Bootstrap initializaton
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/utilities';

// 3rd-Party plugins variables
@import '../../../metronic/assets/sass/core/vendors/plugins/variables';

// Custom layout variables
@import '../../../metronic/assets/sass/custom/variables.layout';
@import '../../../metronic/assets/sass/layout/variables';

@import '../../../metronic/assets/sass/custom/utilities';

// Uppy initialization
@import '~@uppy/core/dist/style.css';
@import '~@uppy/drag-drop/dist/style.css';

.link {
  display: block;
  border-radius: 10px;
  background-color: #50f4fd33;
  padding: 16px;

  @include media-breakpoint-up(lg) {
    padding: 30px 25px;
  }

  &:hover {
    background-color: darken(#50f4fd33, 15%);
  }

  svg {
    fill: #40c3e6;
  }
}

.linkSecondary {
  background-color: rgba(62, 81, 229, 0.15);

  &:hover {
    background-color: darken(rgba(62, 81, 229, 0.15), 15%);
  }

  svg {
    fill: #3e51e580;
  }
}

.icon {
  width: 32px;
  height: 32px;

  @include media-breakpoint-up(lg) {
    width: 40px;
    height: 40px;
  }

  svg {
    width: 32px;
    height: 32px;

    @include media-breakpoint-up(lg) {
      width: 40px;
      height: 40px;
    }
  }
}

.title {
  color: rgba(23, 32, 71, 0.7);
}
