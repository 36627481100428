//
// Main init file of global bootstrap and theme functions, mixins, variables and config
//

// Custom functions & mixins
@import '../../metronic/assets/sass/core/base/functions';
@import '../../metronic/assets/sass/core/base/mixins';
@import '../../metronic/assets/sass/core/components/mixins';
@import '../../metronic/assets/sass/core/vendors/plugins/mixins';

// Custom variables
@import '../../metronic/assets/sass/custom/variables';
@import '../../metronic/assets/sass/core/components/variables';

// Bootstrap initializaton
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/utilities';

// 3rd-Party plugins variables
@import '../../metronic/assets/sass/core/vendors/plugins/variables';

// Custom layout variables
@import '../../metronic/assets/sass/custom/variables.layout';
@import '../../metronic/assets/sass/layout/variables';

@import '../../metronic/assets/sass/custom/utilities';

// Uppy initialization
@import '~@uppy/core/dist/style.css';
@import '~@uppy/drag-drop/dist/style.css';

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  border-color: $primary;
  background-color: $white;
  padding: 20px;
  border-radius: 20px;
  border: 1px solid $coolGrey;

  .headerWrapper {
    display: flex;
    flex-direction: column;
    .imgWrapper {
      width: 77px;
      height: 77px;
      border: 1px solid transparent;
      border-radius: 10px;
    }

    .name {
      display: flex;
      padding-top: 10px;
      font-size: 18px;
      font-weight: bold;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      * {
        display: inline;
      }
    }
  }

  .subjectWrapper {
    display: flex;
    flex-direction: column;
    .name {
      font-size: 18px;
    }

    .category {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      color: rgba($color: $black, $alpha: 0.7);
      font-size: 12px;
      margin-top: 10px;

      > span {
        font-weight: 700;
      }
    }

    .stage {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-top: 10px;
      color: rgba($color: $black, $alpha: 0.7);
      font-size: 12px;

      > span {
        font-weight: 700;
      }
    }

    .description {
      display: flex;
      padding-top: 10px;
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      white-space: pre-wrap;
      * {
        display: inline;
      }
    }
  }

  .priceWrapper {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    text-align: right;
    .price {
      font-size: 16px;
      font-weight: 700;
      color: $text-primary;
    }
  }
}

.containerHovered {
  &:hover {
    -webkit-box-shadow: 11px 15px 46px -30px rgba(66, 68, 90, 1);
    -moz-box-shadow: 11px 15px 46px -30px rgba(66, 68, 90, 1);
    box-shadow: 11px 15px 46px -30px rgba(66, 68, 90, 1);
  }
}
