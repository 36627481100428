//
// SVG Bg Icons
//

@mixin svg-bg-icon($type, $color, $update: false) {
  $bg-image: '';

  // Icon type;
  @if ($type == close) {
    $bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$color}'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/></svg>");
  }

  @if ($type == arrow-top) {
    $bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9 8' fill='#{$color}'><path fill-rule='evenodd' clip-rule='evenodd' d='M4.42111 2.06463C4.22088 1.96161 3.9637 1.9809 3.78597 2.12863L0.177181 5.12847C-0.046034 5.31402 -0.0602611 5.63049 0.145404 5.83532C0.351069 6.04015 0.698744 6.05578 0.921959 5.87023L4.14137 3.19406L7.06417 5.84414C7.27904 6.03896 7.62686 6.03835 7.84105 5.84278C8.05524 5.64721 8.05469 5.33073 7.83982 5.13591L4.54449 2.14806C4.50704 2.1141 4.46541 2.08629 4.42111 2.06463Z'/></svg>");
  }

  @if ($type == arrow-bottom) {
    $bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9 8' fill='#{$color}'><path fill-rule='evenodd' clip-rule='evenodd' d='M4.42111 5.93537C4.22088 6.03839 3.9637 6.0191 3.78597 5.87137L0.177181 2.87153C-0.046034 2.68598 -0.060261 2.36951 0.145404 2.16468C0.351069 1.95985 0.698744 1.94422 0.921959 2.12977L4.14137 4.80594L7.06417 2.15586C7.27904 1.96104 7.62686 1.96165 7.84105 2.15722C8.05524 2.35279 8.05469 2.66927 7.83982 2.86409L4.54449 5.85194C4.50704 5.8859 4.46541 5.91371 4.42111 5.93537Z'/></svg>");
  }

  @if ($type == arrow-start) {
    $bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='#{$color}'><path fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/></svg>");
  }

  @if ($type == arrow-end) {
    $bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='#{$color}'><path fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/></svg>");
  }

  @if ($type == check) {
    $bg-image: url("data:image/svg+xml,<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'> <rect width='20' height='20' rx='4' fill='#3E51E5' fill-opacity='0.05'/> <g clip-path='url(#clip0_1552_8501)'> <path d='M8.66662 12.1148L14.7946 5.98608L15.7379 6.92875L8.66662 14.0001L4.42395 9.75742L5.36662 8.81475L8.66662 12.1148Z' fill='#3E51E5'/> </g> <rect x='0.5' y='0.5' width='19' height='19' rx='3.5' stroke='#3E51E5' stroke-opacity='0.05'/> <defs> <clipPath id='clip0_1552_8501'> <rect width='16' height='16' fill='white' transform='translate(2 2)'/> </clipPath> </defs> </svg>");
  }

  @if ($type == checkbox-checked) {
    $bg-image: url("data:image/svg+xml,<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'> <g filter='url(#filter0_i_3151_1932)'> <rect width='20' height='20' rx='4' fill='#3E51E5'/> <g clip-path='url(#clip0_3151_1932)'> <path d='M8.66649 12.1147L14.7945 5.98602L15.7378 6.92869L8.66649 14L4.42383 9.75736L5.36649 8.81469L8.66649 12.1147Z' fill='white'/> </g> <rect x='0.5' y='0.5' width='19' height='19' rx='3.5' stroke='#3E51E5' stroke-opacity='0.05'/> </g> <defs> <filter id='filter0_i_3151_1932' x='0' y='0' width='20' height='20' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'> <feFlood flood-opacity='0' result='BackgroundImageFix'/> <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape'/> <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/> <feOffset dx='-1' dy='1'/> <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1'/> <feColorMatrix type='matrix' values='0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.01 0'/> <feBlend mode='normal' in2='shape' result='effect1_innerShadow_3151_1932'/> </filter> <clipPath id='clip0_3151_1932'> <rect width='16' height='16' fill='white' transform='translate(2 2)'/> </clipPath> </defs> </svg>");
  }

  // Icon style
  @if $update == false {
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent;
  }

  background-image: escape-svg($bg-image);
}
