//
// Main init file of global bootstrap and theme functions, mixins, variables and config
//

// Custom functions & mixins
@import '../../../../shared/metronic/assets/sass/core/base/functions';
@import '../../../../shared/metronic/assets/sass/core/base/mixins';
@import '../../../../shared/metronic/assets/sass/core/components/mixins';
@import '../../../../shared/metronic/assets/sass/core/vendors/plugins/mixins';

// Custom variables
@import '../../../../shared/metronic/assets/sass/custom/variables';
@import '../../../../shared/metronic/assets/sass/core/components/variables';

// Bootstrap initializaton
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/utilities';

// 3rd-Party plugins variables
@import '../../../../shared/metronic/assets/sass/core/vendors/plugins/variables';

// Custom layout variables
@import '../../../../shared/metronic/assets/sass/custom/variables.layout';
@import '../../../../shared/metronic/assets/sass/layout/variables';

@import '../../../../shared/metronic/assets/sass/custom/utilities';

// Uppy initialization
@import '~@uppy/core/dist/style.css';
@import '~@uppy/drag-drop/dist/style.css';

.icon {
  width: 40px;
  height: 40px;

  border-radius: 5px;
}

.warning {
  background: rgba(62, 81, 229, 0.05);
  border-radius: 10px;
}

.warningIcon {
  background: rgba(253, 80, 111, 0.2);
}

.suggestion {
  border-bottom: 1px solid rgba(62, 81, 229, 0.1);
}

.suggestionIcon {
  background: linear-gradient(
    251.85deg,
    rgba(150, 114, 194, 0.25) 28.12%,
    rgba(62, 81, 229, 0.25) 79.31%
  );
}
