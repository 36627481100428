//
// Main init file of global bootstrap and theme functions, mixins, variables and config
//

// Custom functions & mixins
@import '../../../../shared/metronic/assets/sass/core/base/functions';
@import '../../../../shared/metronic/assets/sass/core/base/mixins';
@import '../../../../shared/metronic/assets/sass/core/components/mixins';
@import '../../../../shared/metronic/assets/sass/core/vendors/plugins/mixins';

// Custom variables
@import '../../../../shared/metronic/assets/sass/custom/variables';
@import '../../../../shared/metronic/assets/sass/core/components/variables';

// Bootstrap initializaton
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/utilities';

// 3rd-Party plugins variables
@import '../../../../shared/metronic/assets/sass/core/vendors/plugins/variables';

// Custom layout variables
@import '../../../../shared/metronic/assets/sass/custom/variables.layout';
@import '../../../../shared/metronic/assets/sass/layout/variables';

@import '../../../../shared/metronic/assets/sass/custom/utilities';

// Uppy initialization
@import '~@uppy/core/dist/style.css';
@import '~@uppy/drag-drop/dist/style.css';

.score {
  box-shadow: 0 3px 5px rgba(79, 79, 176, 0.3);
  border-radius: 20px;
  background-color: #fff;

  &::before {
    position: absolute;
    top: 0;
    left: -80px;
    right: 60%;
    content: '';
    height: 100%;
    background-color: #d2f4fd;
    z-index: 0;
    transform: skew(-25deg);
  }
}

.content {
  min-height: 240px;
}

.avatar {
  width: 90px;
  height: 90px;
}

.maxScore,
.date {
  color: rgba(23, 32, 71, 0.4);
}
