//
// Main init file of global bootstrap and theme functions, mixins, variables and config
//

// Custom functions & mixins
@import '../../../../../shared/metronic/assets/sass/core/base/functions';
@import '../../../../../shared/metronic/assets/sass/core/base/mixins';
@import '../../../../../shared/metronic/assets/sass/core/components/mixins';
@import '../../../../../shared/metronic/assets/sass/core/vendors/plugins/mixins';

// Custom variables
@import '../../../../../shared/metronic/assets/sass/custom/variables';
@import '../../../../../shared/metronic/assets/sass/core/components/variables';

// Bootstrap initializaton
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/utilities';

// 3rd-Party plugins variables
@import '../../../../../shared/metronic/assets/sass/core/vendors/plugins/variables';

// Custom layout variables
@import '../../../../../shared/metronic/assets/sass/custom/variables.layout';
@import '../../../../../shared/metronic/assets/sass/layout/variables';

@import '../../../../../shared/metronic/assets/sass/custom/utilities';

// Uppy initialization
@import '~@uppy/core/dist/style.css';
@import '~@uppy/drag-drop/dist/style.css';

.wrapper {
  border-bottom: 1px solid rgba(62, 81, 229, 0.15);

  &:last-of-type {
    border-bottom: none;
  }
}

.avatar {
  width: 60px;
  height: 60px;
}

.download {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  background: rgba(62, 81, 229, 0.1);
  border-radius: 8px;
  flex-shrink: 0;

  svg {
    width: 21px;
    height: 21px;
  }

  &[disabled] {
    opacity: 0.4;
    pointer-events: none;
  }
}

.remove {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  background: $danger-light;
  border-radius: 8px;
  flex-shrink: 0;

  svg {
    width: 16px;
    height: 16px;
  }

  &[disabled] {
    opacity: 0.4;
    pointer-events: none;
  }
}
