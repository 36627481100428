//
// Main init file of global bootstrap and theme functions, mixins, variables and config
//

// Custom functions & mixins
@import '../../../../shared/metronic/assets/sass/core/base/functions';
@import '../../../../shared/metronic/assets/sass/core/base/mixins';
@import '../../../../shared/metronic/assets/sass/core/components/mixins';
@import '../../../../shared/metronic/assets/sass/core/vendors/plugins/mixins';

// Custom variables
@import '../../../../shared/metronic/assets/sass/custom/variables';
@import '../../../../shared/metronic/assets/sass/core/components/variables';

// Bootstrap initializaton
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/utilities';

// 3rd-Party plugins variables
@import '../../../../shared/metronic/assets/sass/core/vendors/plugins/variables';

// Custom layout variables
@import '../../../../shared/metronic/assets/sass/custom/variables.layout';
@import '../../../../shared/metronic/assets/sass/layout/variables';

@import '../../../../shared/metronic/assets/sass/custom/utilities';

// Uppy initialization
@import '~@uppy/core/dist/style.css';
@import '~@uppy/drag-drop/dist/style.css';

.section {
  background-color: $white;
  padding: 20px 50px;
  box-shadow: 0 6px 50px 5px rgba(79, 79, 176, 0.05);
  border: 1px solid transparent;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.container {
  background-color: $lavender;
}

.buttonCollapse {
  z-index: 30;
  position: absolute;
  width: 100%;
  bottom: -15px;
  left: 0;
}

.reportContainer {
  background-color: $white;
  padding: 20px 50px;
  box-shadow: 0 6px 50px 5px rgba(79, 79, 176, 0.05);
  border: 1px solid transparent;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
